import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer>
    <div className="bottom">
      <div className="container">
        <div className="row">
          <div className="logo col-md-2 col-sm-12">
            <Link to="/">
              <img
                width="113"
                height="84"
                alt="The Devonshire Group"
                src="https://cdn1.accropress.com/devonshire-logo-white.svg"
              />
            </Link>
          </div>
          <div className="col-md-4 col-sm-12 white">
            <div className="header">Our Locations</div>{" "}
            <Link to="/derbyshire-estate/">Derbyshire Estate</Link>{" "}
            <Link to="/yorkshire-estate/">Yorkshire Estate</Link>{" "}
            <Link to="/irish-estate/">Irish Estate</Link>
          </div>
          <div className="col-md-4 col-sm-12 white">
            <div className="header">Our Hotels</div>{" "}
            <Link to="/hotels-and-restaurants/">
              Devonshire Hotels and Restaurants
            </Link>
          </div>
          <div className="logo col-md-2 col-sm-12">
            <div style={{ background: "#fff", width: "200px" }}>
              <img
                width="200px"
                height="auto"
                src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/Devonshire%20Group/Devonshire.jpg"
                alt="disability confident employer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="details">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="copyright">© The Devonshire Group 2019</span>{" "}
            <span> | </span>{" "}
            <a
              href="https://devonshiregroup.co.uk/privacy-policy/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Privacy &amp; Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
